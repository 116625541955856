<template>
  <div class="footer">
    <router-link class="footer-logo" to="/"
      ><img src="../assets/logo.svg"
    /></router-link>
    <div class="footer-info">
      <div class="footer-links">
        <!-- <router-link class="footer-link" to="/">DISCOVER</router-link> -->
        <router-link class="footer-link" to="/team">TEAM</router-link>
        <router-link class="footer-link" to="/#approbation"
          >APPROBATIONS</router-link
        >
        <router-link  class="footer-link"  to="/videos">VIDEOS</router-link>

        <!-- <router-link class="footer-link" to="/blog">BLOG</router-link> -->
        <router-link class="footer-link" to="/#connect">CONNECT</router-link>
        <router-link class="footer-link" to="/donate">DONATE</router-link>
        <!-- <router-link class="footer-link" to="/he">עברית</router-link> -->
        <a
          class="footer-link"
          target="_blank"
          href="https://www.facebook.com/Lamdeini"
          ><img src="../assets/facebook.svg" alt="facebook"
        /></a>
      </div>
      <p class="footer-small">
        Email
        <a href="mailto:office@lamdeini.org"> office@lamdeini.org </a>
        | Israel Phone 072-392-6886 | USA Phone 718-564-6211 | Fax (Israel)
        072-273-2062
        <br>
        Lamdeini is a 501c3 non-profit (EIN: 82-3394527)
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "botfooter",
};
</script>
