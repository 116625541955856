<template>
  <div class="rtl">
    <TopnavHe></TopnavHe>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">תרומה</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="don-top">
        <div class="don-top-txt-wrap">
          <p class="txt24 light mb-30">
            חבר יקר,
            <br />
            רשימת ההמתנה שלנו מתפוצצת. כל כך הרבה בנים יקרים נאבקים להצליח.
            אנחנו צריכים לעזור להם. אנא שקול לתרום ללמדייני. אסור לנו לאכזב את
            הילדים שלנו.
            <br />
            תודה מקרב לב, מאיר אורץ.
          </p>
          <a
            target="_blank"
            href="https://donorbox.org/lamdeini-website-donation-form"
            class="btn-line"
            >תרומה</a
          >
          <p>
            התרומה שלך ניתנת לניכוי מס. <br />
            למדני היא ארגון צדקה רשום 501 c3.
          </p>
        </div>
        <img src="../assets/donation.jpg" class="don-img" />
      </div>
      <div class="don-bot">
        <div class="don-gray-box">
          <p class="don-small-title">
            ילדים מחוץ לדרך <br />
            משבר קהילתי.
          </p>
          <p>
            יותר מדי בנים מורדים בגלל לקויות למידה, נמוך הערכה עצמית, וחוסר
            סיפוק בלמידה שלהם. סטודנטים שמתקשים ללמוד ולהבין את הגמרא הופכים
            לעתים קרובות מאוכזב מכל אורח החיים התורני.
          </p>
          <p>
            למדני היא יוזמה פורצת דרך המספקת לכל ילד עם הכלים ללמוד גמרא בצורה
            יעילה. למדייני, נוסדה על ידי הרב מאיר אורץ, הקים תכנית חונכות
            מקצועית. מורים מוכשרים ומאומנים יוצרים תוכניות למידה אישיות למקסם את
            הפוטנציאל של כל תלמיד ולייצר ביטחון עצמי, לומד עצמאי.
          </p>
          <p>
            למדני מסבסד את עלות החונכים כדי לאפשר לכל ילד את סיכוי להצליח. אבל
            אילוצים כלכליים מגבילים את מספר התלמידים ניתן להתקבל לתכנית. איך
            נוכל למנוע מילד שנאבק להתחבר לגמרא את האפשרות לפרוח לפני שיוותר?
            למנוע טרגדיות ולהציל את ילדינו היקרים. השקיעו ב עתידו של כלל ישראל.
          </p>
        </div>
        <div class="don-list-wrap">
          <div class="don-item">
            <p class="don-item-txt">
              Partner to subsidize the costs of tutoring a boy for a month
            </p>
            <p class="don-price">$220</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">Sponsor a boy for a month</p>
            <p class="don-price">$440</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">Pay a tutor’s salary for a month</p>
            <p class="don-price">$1850</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">
              Partner to subsidize the costs of tutoring a boy for a year
            </p>
            <p class="don-price">$2650</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">Sponsor a boy for a year</p>
            <p class="don-price">$5300</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">
              Sponsor the LAMDEINI course for mechanchim
            </p>
            <p class="don-price">$10,000</p>
          </div>
          <div class="don-item">
            <p class="don-item-txt">Pay a tutor’s salary for a year</p>
            <p class="don-price">$22,200</p>
          </div>
        </div>
      </div>
    </div>
    <BotfooterHE></BotfooterHE>
  </div>
</template>

<script>
import BotfooterHE from "@/components/BotfooterHe.vue";
import TopnavHe from "@/components/TopnavHe.vue";
export default {
  components: {
    TopnavHe,
    BotfooterHE,
  },
  name: "Donate",
};
</script>
