<template>
  <div class="footer">
    <router-link class="footer-logo" to="/he"
      ><img src="../assets/logo.svg"
    /></router-link>
    <div class="footer-info">
      <div class="footer-links">
        <!-- <router-link class="footer-link" to="/he">לגלות</router-link> -->
        <router-link class="footer-link" to="/he/team">צוות</router-link>
        <router-link class="footer-link" to="/he#approbation"
          >הסכמה</router-link
        >
        <router-link class="footer-link" to="/he/blog">בלוג</router-link>
        <router-link class="footer-link" to="/he#connect"
          >יצירת קשר</router-link
        >
        <router-link class="footer-link" to="/he/donate">תרומה</router-link>
        <router-link class="footer-link" to="/">English</router-link>
        <a
          class="footer-link"
          target="_blank"
          href="https://www.facebook.com/Lamdeini"
          ><img src="../assets/facebook.svg" alt="facebook"
        /></a>
      </div>
      <p class="footer-small">
        דוא״ל
        <a href="mailto:office@lamdeini.org"> office@lamdeini.org </a>
        | טלפון ישראל 072-392-6886 | טלפון ארה״ב 718-564-6211 | פקס (ישראל)
        072-273-2062
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "botfooterHe",
};
</script>
