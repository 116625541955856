<template>
  <div class="rtl">
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">צוות</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="team">
        <div class="team-gray">
          <div class="team-member">
            <img src="../assets/rav.jpg" class="team-member-img" />
            <div>
              <p class="txt24">הרב מאיר אורץ</p>
              <p class="txt18">מייסד ומנהל</p>
            </div>
          </div>
          <p class="txt16">
            Rabbi Meir Oratz, is a scion of the renowned Oratz family of
            educators and has taught in the United States and Eretz Yisrael for
            21 years. Based on the legendary methods of Rav Yeshaya Weber,
            Shlita, Rabbi Oratz has developed a unique program that assists
            students in mastering the study of Gemara. Rabbi Oratz has been
            incredibly successful in helping students both as a classroom
            teacher and as a private tutor.
          </p>
          <p class="txt16">
            Witnessing how many students who struggled to connect to Gemara
            eventually became disillusioned with a Torah way of life, Rabbi
            Oratz has a passionate vision of helping struggling students on a
            larger scale. To this end, Rabbi Oratz has designed LAMDEINI, a
            unique tutoring program which provides students with the tools to
            blossom into confident learners. These at-risk students regain
            satisfaction in their learning and thrive as a part of our
            community.
          </p>
        </div>
        <!-- <div class="team-blue">
          <div class="team-small-white-line"></div>
          <h2 class="txt40 light no-marg">Leadership</h2>
          <div class="team-member on-blue">
            <img
              src="../assets/rav-yitzchok-berkowitz.jpg"
              class="team-member-img"
            />
            <div>
              <p class="txt24">HaRav Yitzchak Berkovits, Shlita</p>
              <p class="txt18">Rabbinical Supervisor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/r-shmuel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Shmuel Kamenetsky, Shlita</p>
              <p class="txt18">Rabbinical Advisor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/rabbi-bender-1.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Yaakov Bender, Shlita</p>
              <p class="txt18">Chinuch Advisor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/rabbi-russel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Shimon Russell, L.C.S.W., B.C.D</p>
              <p class="txt18">Clinical Advisor</p>
            </div>
          </div>
        </div> -->
        <div class="team-blue">
          <div class="team-small-white-line"></div>
          <h2 class="txt40 light no-marg">Leadership</h2>
          <!-- <div class="team-member on-blue">
            <img
              src="../assets/rav-yitzchok-berkowitz.jpg"
              class="team-member-img"
            />
            <div>
              <p class="txt24">HaRav Yitzchak Berkovits, Shlita</p>
              <p class="txt18">Rabbinical Supervisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/r-shmuel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Shmuel Kamenetsky, Shlita</p>
              <p class="txt18">Rabbinical Advisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/rabbi-bender-1.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Yaakov Bender, Shlita</p>
              <p class="txt18">Chinuch Advisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/rabbi-russel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Shimon Russell, L.C.S.W., B.C.D</p>
              <p class="txt18">Clinical Advisor</p>
            </div>
          </div> -->
          <div class="team-member  on-blue">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Yisroel Abramov</p>
              <p class="txt18">Director of Training Program & Professional Development</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Yirmiyahu Rotenberg</p>
              <p class="txt18">Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Dovid Nadler</p>
              <p class="txt18">Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Dovid Farber</p>
              <p class="txt18">Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Chaim Novice</p>
              <p class="txt18">Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Tzvi Drutman</p>
              <p class="txt18">Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Mrs. Basya Lifshutz</p>
              <p class="txt18">Office Manager</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Team",
};
</script>
