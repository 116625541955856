<template>
  <div class="blog-page rtl">
    <TopnavHe></TopnavHe>
    <div class="main-sec">
      <div class="back-wrap">
        <router-link to="/he/blog"
          ><img src="../assets/back.svg" alt="Back" />חזרה</router-link
        >
      </div>
      <!-- <div class="share">
        <a class="share-btn" href=""
          ><img src="../assets/facebook-share.svg" alt="facebook"
        /></a>
        <a class="share-btn" href=""
          ><img src="../assets/twitter-share.svg" alt="twitter"
        /></a>
      </div> -->
      <div class="blog-wrap">
        <h1 class="blog-title">{{ blog.title }}</h1>
        <p>{{ blog.blurb }}</p>
        <p>{{ new Date(blog.date).toLocaleDateString() }}</p>
      </div>
      <img class="blog-big-img" :src="blog.image.imageURL" :alt="blog.title" />
      <div class="blog-wrap" v-html="blog.body"></div>
    </div>
    <BotfooterHe></BotfooterHe>
  </div>
</template>

<script>
import BotfooterHe from "@/components/BotfooterHe.vue";
import TopnavHe from "@/components/TopnavHe.vue";
export default {
  components: {
    TopnavHe,
    BotfooterHe,
  },
  data() {
    return {
      blog: {},
    };
  },
  async beforeMount() {
    const { params } = this.$route;
    let { data: blog } = await this.$db
      .collection("blogs")
      .item(params.id)
      .get();
    this.blog = blog;
  },
  name: "BlogPage",
};
</script>
