<template>
  <div>
    <div class="top-tri-bg top"></div>
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">Inspirational Content</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="w1170 video-boxes shorts">
        <div class="iframe-wrap " v-for="video in media" :key="video._id" >
            
              <iframe class="video-wrap" :src="$embedder(video.video)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  name: "Inspirational",
  components: {
    Topnav,
    Botfooter,
  },
  data() {
    return {
      media: [],
    };
  },
  
  mounted() {
    this.getVideoList();
  },

  methods: {
    async getVideoList() {
      const { data: media } = await this.$db.collection("inspirationalContent").get();
      this.media = media;
  },
  },
};

</script>



<style>
.video-boxes.shorts {
  justify-content: center;
}
.shorts.video-boxes .iframe-wrap{
  height: 600px;
  flex: 0 0 25%;
  justify-content: center;
}

.shorts.video-boxes .iframe-wrap .video-wrap{
  max-height: none;
}

</style>