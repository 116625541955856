<template>
  <div class="rtl">
    <div class="top-tri-bg top"></div>
    <topnavHe></topnavHe>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">בלוג</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="w1170">
        <router-link
          v-for="blog in blogList.slice(0, 1)"
          :key="blog._id"
          :to="`/he/blog/${blog._id}`"
          class="main-blog-box"
        >
          <img :src="blog.image.imageURL" class="main-blog-img" />
          <div class="main-blog-txt-box">
            <p class="main-blog-title">{{ blog.title }}</p>
            <p class="blog-info">
              {{ blog.blurb }}
            </p>
            <p class="blog-date">
              {{ new Date(blog.date).toLocaleDateString() }}
            </p>
          </div>
        </router-link>
      </div>
      <div class="w1170 blogs-boxes">
        <router-link
          v-for="blog in blogList.slice(1, 100000)"
          :key="blog._id"
          :to="`/he/blog/${blog._id}`"
          class="blog-link-box"
        >
          <img
            :src="blog.image.imageURL"
            :alt="blog.title"
            class="blog-box-img"
          />
          <div class="blog-inner">
            <p class="blog-box-title">{{ blog.title }}</p>
            <p class="blog-info">
              {{ blog.blurb }}
            </p>
            <p class="blog-date">
              {{ new Date(blog.date).toLocaleDateString() }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <BotfooterHe></BotfooterHe>
  </div>
</template>

<script>
import BotfooterHe from "@/components/BotfooterHe.vue";
import TopnavHe from "@/components/TopnavHe.vue";
export default {
  name: "Blog",
  components: {
    TopnavHe,
    BotfooterHe,
  },
  data: () => ({
    blogList: [],
  }),
  mounted() {
    this.getBlogList();
  },
  methods: {
    async getBlogList() {
      const { data: blogList } = await this.$db.collection("blogs").get();
      this.blogList = blogList;
    },
  },
};
</script>
