import blogpage from "../views/Blogpage.vue";
import blog from "../views/Blog.vue";
import donate from "../views/Donate.vue";
import team from "../views/Team.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import blogpageHe from "../views/BlogpageHe.vue";
import blogHe from "../views/BlogHe.vue";
import donateHe from "../views/DonateHe.vue";
import teamHe from "../views/TeamHe.vue";
import HomeHe from "../views/HomeHe.vue";
import Videos from "../views/Videos.vue";
import Inspirational from "../views/Inspirational.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/team", name: "Team", component: team },
  {
    path: "/far-rockaway",
    name: "ParlorMeeting",
    component: () => import("../views/ParlorMeeting.vue"),
  },
  {
    path: "/lakewood",
    name: "DarcheiTorah",
    component: () => import("../views/DarcheiTorah.vue"),
  },
  { path: "/donate", name: "Donate", component: donate },
  { path: "/videos", name: "Videos", component: Videos },
  { path: "/inspo", name: "Inspirational", component: Inspirational },

  { path: "/blog", name: "Blog", component: blog },
  { path: "/blog/:id", name: "blog", component: blogpage },
  { path: "/he", name: "Home", component: HomeHe },
  { path: "/he/team", name: "Team", component: teamHe },
  { path: "/he/donate", name: "Donate", component: donateHe },
  { path: "/he/blog", name: "Blog", component: blogHe },
  { path: "/he/blog/:id", name: "blog", component: blogpageHe },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash)
      return {
        behavior: "smooth",
        selector: to.hash,
        offset: { x: 0, y: 100 },
      };
    return savedPosition || { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  if (!to.hash) return;
  setTimeout(() => {
    const el = document.querySelector(to.hash);
    if (!el) return;
    const y = el.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 80,
      left: 0,
      behavior: "smooth",
    });
  }, 50);
});

export default router;
