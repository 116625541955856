<template>
  <div class="top-nav">
    <div class="nav-body">
      <router-link to="/" class="logo"
        ><img src="../assets/logo.svg"
      /></router-link>
      <div href="" class="menu-icon" @click="opennavbar = true">
        <img src="../assets/menu.svg" />
      </div>
      <div class="menu" :class="{ openmenu: opennavbar }">
        <div class="close-btn" @click="opennavbar = false">
          <img src="../assets/close.svg" />
        </div>

        <!-- <router-link exact to="/" class="nav-link">DISCOVER</router-link> -->
        <router-link exact to="/team" class="nav-link">TEAM</router-link>
        <router-link exact to="/#approbation" class="nav-link">
          APPROBATIONS
        </router-link>
        <router-link exact to="/videos" class="nav-link">Media</router-link>
        <router-link exact to="/inspo" class="nav-link">Inspirational Content</router-link>
        <!-- <router-link exact to="/blog" class="nav-link">BLOG</router-link> -->
        <router-link exact to="/#connect" class="nav-link">CONNECT</router-link>
        <router-link exact to="/donate" class="nav-link">DONATE</router-link>
        <!-- <router-link exact to="/he" class="nav-link">עברית</router-link> -->
      </div>
    </div>
    <div class="development" :class="{ developmentopen: developmentopen }">
      <button @click="developmentopen = false">✕</button>
      <p>
        The site is still under active development, if you have feedback we’d
        love to hear
        <a href="mailto: info@lamdeini.com"> info@lamdeini.com</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    opennavbar: false,
    developmentopen: false,
  }),
  name: "topnav",
};
</script>
