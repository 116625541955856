<template>
  <div>
    <div class="top-tri-bg top"></div>
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">Media</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="w1170 video-boxes">
        <div class="iframe-wrap" v-for="video in media" :key="video._id" >
            <div class="video-wrap" v-html="video.video"></div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  name: "Media",
  components: {
    Topnav,
    Botfooter,
  },
  data() {
    return {
      media: [],
    };
  },
  
  mounted() {
    this.getVideoList();
  },

  methods: {
    async getVideoList() {
      const { data: media } = await this.$db.collection("media").get();
      this.media = media;
  },
  },
};
</script>


