<template>
  <div>
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">Team</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="team">
<div class="team-gray-wrap">
  <div class="team-gray" v-for="member in team.filter(m=> m.type.value === 'featured')" :key="member._id" >
            <div class="team-member">
              <img :src="member.image.imageURL" class="team-member-img" />
              <div>
                <p class="txt24"> {{ member.name }}</p>
                <p class="txt18"> {{ member.title }}</p>
              </div>
            </div>
          <div v-html="member.bio"></div>
          </div>
        </div>
        <div class="team-blue">
          <div class="team-small-white-line"></div>
          <h2 class="txt40 light no-marg">Leadership</h2>
          <div class="team-member on-blue" v-for="member in team.filter(m=> m.type.value === 'leadership')" :key="member._id"
            >
            <img :src="member.image.imageURL" class="team-member-img" />
            <div>
              <p class="txt24"> {{ member.name }}</p>
              <p class="txt18"> {{ member.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <video
      controls
        class="bg-vid"
        poster="../assets/bg-pic.jpg"
        id="bgvid"
      >
        <source
          src="https://sfo2.digitaloceanspaces.com/bagel/Lamdeini/LamdeiniFullVideo02.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <botfooter></botfooter>
  </div>
</template>



<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  name: "Team",
  components: {
    Topnav,
    Botfooter,
  },
  data() {
    return {
      team: [],
    };
  },
  
  mounted() {
    this.getTeamList();
  },

  methods: {
    async getTeamList() {
      const { data: team } = await this.$db.collection("team").get();
      this.team = team;
  },
  },
};
</script>