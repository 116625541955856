import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import Bagel from '@bageldb/bagel-db'

const bagelToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6ImNldHRmMzIyM2FrczczZDZuaGdnIiwicHJvamVjdElEIjoiY2V0dGY1aTIzYWtzNzNkNm5oa2ciLCJhdWQiOiJodHRwczovL2FwcC5iYWdlbGRiLmNvbSIsImp0aSI6IjE0NDRkNjQwLTg2MmItNGI5Yy1hODFlLWUyZDY5NjBlODMyZCIsImlzcyI6Imh0dHBzOi8vYXV0aGVudGljYXRpb24uYmFnZWxkYi5jbyIsInN1YiI6ImNldHRmNWkyM2FrczczZDZuaGtnIn0.dj10J24yQD7Ez0k6r0UP9xJHLHH5MDprnIbwH4DFPMQnB84tbNdVMSnIgmGSqFTldy3ZTN8J_WEMF5FUisG5UEank2wQNO7idL5-IQJo2v0FbYWXMuWsJa7P4-gOmdUv4Kva5mh_cYXSJmHoAAsHrqqxPXNPzpx_hidmdQZBQCTA1ALuQhMB3DjHsRX5Ri81u6U_5zav7rBLyxFlx9mr_6TetB5jdWAzi2iCuVXNrQ7ycGCYkssSkyATaCXJ_au6zz54K3IQYuug9UMYKN55l5HPDkYAhAXYBubaXRwCK1kideckJol7UCMlThdBaUEPybrKRriCuKV2Mlx9NyHxwvO4WTedUnGcApHfD_X68wk7_vYKR7bqGM83WGaffhktEGXLYM6oCxFLzw-7kqxVEK8HMfyJaRyLDPMAz5koiv4HU848R1LTYePVkLh6rfZ-SreJPXBzoMb18esU32vrbCZ_fibqNRTW7w6iY6HWOZGjVS7eBeXABcsydWfwvCQHe5tVLfUi7_J38coCFGiC-lJOr9VADgb5mVF4pSbyJlVncHXBS-WVwPrEkrnVDZGAsgr99kxjpxBy0V334lvMiDbebtTiOy9id2ZJcgZjZVs8UDaD53E6y_HkOI4S6It9w6JMFSNT2jJpNtuVKxk417NTjbqD_OXMPhkzjZ7ZriI'
const db = new Bagel(bagelToken)
Vue.prototype.$db = db
Vue.use(VueRouter)

Vue.config.productionTip = false

const getYid = (url) => {
  let yid;
  if (url.match('shorts')){
    yid = url.split('?').shift().split('/').pop()
  } else {
    yid = url.trim().match(/([\w-]+)(&|$)/g)[0]
  }
  return yid.replace(/^&|&$/,"")
} 
Vue.prototype.$getYid = getYid
Vue.prototype.$embedder = (url) => {
  let embedUrl = url;
  if ( url.match(/youtu/)){
    let yid = getYid(url)
    let time="";
    if (url.match(/t=/)){
      let tArray = url.split(/\?|&/)
      
      tArray.forEach(element => {
        if (element.match(/^t/)){
          time = 'start='+element.split("=")[1]+'&';
        }
      });
    }
    
    embedUrl = 'https://www.youtube.com/embed/'+yid+'?'+time;
    console.log(embedUrl)
  } else if(url.match(/vimeo/)){
    let regexp = /com\/(\w)*/gi;
    let vidId = url.match(regexp)
    if (vidId){
      vidId = vidId[0].replace(/com/,"");
    }
    embedUrl = "//player.vimeo.com/video"+vidId;
    if (url.match(/player.vimeo/)) embedUrl = url;
  }
  return embedUrl
},

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')