<template>
  <div class="rtl">
    <TopnavHe></TopnavHe>
    <div class="popup" :class="{ popupactive: popupactive }">
      <button class="close-vid-btn" type="button" @click="popupactive = false">
        <img src="../assets/close.svg" alt="close" />
      </button>
      <iframe
        class="video"
        src="https://www.youtube.com/embed/y_ZJdcWAwHM"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen=""
      ></iframe>
    </div>
    <div class="hero">
      <div class="hero-top-blue"></div>
      <div class="hero-body">
        <div class="hero-main-title-wrap">
          <h1 class="hero-title">
            כל ילד וילד
            <br />
            כולנו לומדי תורתך
          </h1>
          <p class="txt20">
            למדני היא יוזמה המבוססת על שיטות מוכחות אותנטיות להבטיח שכל ילד יהיה
            מצויד להשיג את הצ'לק שלו, שלהם חלק, בתורה.
          </p>
          <button class="main-btn" type="button" @click="popupactive = true">
            <img src="../assets/play.svg" alt="play video" />צפו בסרטון
          </button>
        </div>
      </div>

      <div class="top-tri-bg"></div>
    </div>
    <div id="approach" class="sec">
      <div class="center">
        <h2 class="txt60">הדרך שלנו</h2>
        <div class="small-title-line center"></div>
      </div>
      <div class="approach-wrap">
        <div class="approach-box">
          <img src="../assets/icon1.svg" class="approach-icon" />
          <p class="txt24">Training</p>
          <p class="txt16">
            Tutors are carefully vetted and then trained to use our unique
            methods. Progress is monitored.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon2.svg" class="approach-icon" />
          <p class="txt24">Assessment</p>
          <p class="txt16">
            We work in the schools in conjunction with parents and rebbeim to
            identify students at risk, and thoroughly evaluate each individual
            situation.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon3.svg" class="approach-icon" />
          <p class="txt24">Remediation</p>
          <p class="txt16">
            Our methods are unique and proven to be effective. They have been
            fully reviewed and approved by leading rabbanim and mechanchim.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon4.svg" class="approach-icon" />
          <p class="txt24">Reintegration</p>
          <p class="txt16">
            Through early detection and preventative intervention, we stop the
            deadly at-risk snowball effect in its tracks. Students catch up to
            their peer group and are ready to succeed.
          </p>
        </div>
      </div>
      <div class="w1170"></div>
    </div>
    <div class="sec blue-sec">
      <div class="gold-box">
        <div class="gold-box-txt-wrap">
          <div class="small-white-line"></div>
          <p class="txt34">
            When a student asks his rebbe to teach him Torah, if the rebbe does,
            Hashem fills the eyes of both with light
          </p>
          <p class="txt16 mb-30">Talmud Bavli T'mura 16a</p>
          <router-link to="/he/donate" class="btn-line"
            >עזרו לילדים שלנו</router-link
          >
        </div>
        <img src="../assets/lamdeini2.jpg" class="gold-box-img" />
      </div>
      <div class="top-white"></div>
      <div class="under-gold-box">
        <p class="txt40">Is your child struggling with Gemara?</p>
        <router-link to="/#connect" class="btn-line">We can help.</router-link>
      </div>
    </div>
    <div class="gray-sec">
      <div class="mission-wrap">
        <img src="../assets/lamdeini3.jpg" class="mission-img" />
        <div class="mission-txt-wrap">
          <div class="small-blue-line"></div>
          <p class="txt16">
            LAMDEINI’s mission is to provide each child with the tools to learn
            Gemara effectively. LAMDEINI’s professional tutoring program aims to
            transform students struggling to connect to Gemara into confident,
            independent learners based on Rabbi Meir Oratz's proven methodology.
          </p>
          <p class="txt16">
            LAMDEINI’s individualized, subsidized learning plans provide
            effective, affordable solutions for our most precious resource: our
            children.
          </p>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="w1170">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">איך הלוואי ויכולתי ללמוד: סיפור</h3>
        <div class="story-wrap">
          <div class="story-box">
            <img src="../assets/quotation-marks.svg" class="quotation-marks" />
            <p class="txt16">
              My name is Yitzi and I used to love school. I got great report
              cards and had lots of friends. Learning with Tatty before going to
              bed was the highlight of my day. I knew Tatty thought I was smart
              — I would hear him tell Mommy that I learned like a real yeshivah
              bachur.
            </p>
            <p class="txt16">
              But now I turned stupid. I don’t know the answers in my Gemara
              class because I never know who is saying what. I try and try but I
              can’t seem to remember what the words of the Gemara mean. It’s so
              different to the Chumash.
            </p>
            <p class="txt16">
              My Rebbi used to explain the Gemara lots of times but now he goes
              so fast because he says we’re not beginners anymore. I hate school
              now. I don’t even try to concentrate anymore. What’s the point? My
              friends all know that now I’m dumb. Tatty doesn’t even try to
              learn Gemara with me anymore — he gets frustrated and says that
              I’m not trying. So I hate Tatty and I hate school and I hate my
              friends. I want to run away from school and my house and never
              come back.
            </p>
            <p class="story-last">
              Yitzi doesn’t know yet that all hope is not lost. We can help him.
              We can save him. With your participation we can expand our
              tutoring program and give boys like Yitzi another chance to
              succeed.
            </p>
          </div>
          <img src="../assets/LamdeiniLearning-136.jpg" class="story-img" />
        </div>
      </div>
    </div>
    <div id="approbation" class="approbation-sec">
      <div class="approbation-wrap">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">הסכמה</h3>
        <p>
          LAMDEINI has received approval, encouragement and guidance from
          leading Rabbanim (See the below letters):
        </p>
        <div class="approbation-links">
          <a
            class="approbation-link"
            href="/pdf/HaRav-Yitzchok-Berkowitz.pdf"
            target="_blank"
            >HaRav Yitzchak Berkovits</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Berkowitz-and-Rav-Zilberstein-Hebrew.pdf"
            >HaRav Yitzchak Berkovits and Rav Zilberstein Hebrew</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Elya-Brudny.pdf"
            >HaRav Elya Brudny</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Bender-and-Rabbi-Feiner.pdf"
            >Rabbi Bender and Rabbi Feiner</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/HaRav-Shmuel-Kaminetsky.pdf"
            >HaRav Shmuel Kamenetsky
          </a>
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Shimon-Russel-LCSW.pdf"
            >Rabbi Shimon Russell, LCSW</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Chofetz-Chayim-School-and-Rav-Zilberstein.pdf"
            >Chofetz Chaim School and Rav Zilberstein</a
          >
        </div>
      </div>
      <div class="tri-white-bg"></div>
    </div>
    <div id="connect" class="sec-dark">
      <div class="connect">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">יצירת קשר</h3>
        <div class="form-wrap">
          <form
            @submit.prevent="submitForm"
            v-if="formStatus != 'success'"
            class="form"
          >
            <div>
              <input
                placeholder="שם מלא (חובה)"
                type="text"
                name="name"
                class="field"
                required=""
                v-model="form.name"
              />
              <input
                v-model="form.email"
                placeholder="דוא״ל (חובה)"
                type="email"
                name="email"
                required=""
                class="field"
              />
              <input
                placeholder="נושא"
                type="text"
                name="name"
                class="field"
                required=""
                v-model="form.subject"
              />
            </div>
            <div>
              <textarea placeholder="הודעה" class="field big"></textarea>

              <input type="submit" value="שליחה" class="field-btn" />
            </div>
          </form>

          <div class="form-fail" v-if="formStatus == 'err'">
            <p>חלה שגיאה בעת השליחה</p>
          </div>
          <div class="form-success" v-if="formStatus === 'success'">
            <p>
              תודה רבה
              <br />
              ההודעה נשלחה בהצלחה
            </p>
          </div>
        </div>
      </div>
    </div>
    <BotfooterHe></BotfooterHe>
  </div>
</template>

<script>
import BotfooterHe from "@/components/BotfooterHe.vue";
import TopnavHe from "@/components/TopnavHe.vue";
export default {
  components: {
    TopnavHe,
    BotfooterHe,
  },
  data() {
    return {
      popupactive: false,
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      formStatus: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.$db.collection("connect").post(this.form);
        this.formStatus = "success";
        this.submitting = false;
      } catch (err) {
        this.formStatus = "err";
        this.submitting = false;
      }
    },
  },
  name: "Home",
};
</script>
