<template>
  <div class="top-nav">
    <div class="nav-body">
      <router-link to="/he" class="logo"
        ><img src="../assets/logo.svg"
      /></router-link>
      <div href="" class="menu-icon" @click="opennavbar = true">
        <img src="../assets/menu.svg" />
      </div>
      <div class="menu" :class="{ openmenu: opennavbar }">
        <div class="close-btn" @click="opennavbar = false">
          <img src="../assets/close.svg" />
        </div>

        <!-- <router-link exact to="/he" class="nav-link">לגלות</router-link> -->
        <router-link exact to="/he/team" class="nav-link">צוות</router-link>
        <router-link exact to="/he#approbation" class="nav-link">
          הסכמה
        </router-link>
        <router-link exact to="/he/blog" class="nav-link">בלוג</router-link>
        <router-link exact to="/he#connect" class="nav-link"
          >יצירת קשר</router-link
        >
        <router-link exact to="/he/donate" class="nav-link">תרומה</router-link>
        <router-link exact to="/" class="nav-link">English</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    opennavbar: false,
  }),
  name: "topnav",
};
</script>
